import request from "@/utils/request";
import Cookies from "js-cookie";

//获取部门列表（一级展示） departmentCode
export function getList(params) {
  return request({
    url: "/department/getList",
    method: "get",
    params: params,
  });
}

//树形
export function buildTree(params) {
  return request({
    url: "/department/buildTree",
    method: "get",
    params: params,
  });
}

//树形
export function getAllList(params) {
  return request({
    url: "/department/getAllList",
    method: "get",
    params: params,
  });
}
