import request from "@/utils/request";

//更新办公用品
export function editOfficeSupplies(data) {
  return request({
    url: "/office-supplies/editOfficeSupplies",
    method: "put",
    data: data,
  });
}


//获取办公用品领取记录列表
export function queryOSRecordList(params) {
  return request({
    url: "/office-supplies/queryOSRecordList",
    method: "get",
    params: params,
  });
}


//获取办公用品详情
export function queryOfficeSuppliesInfo(params) {
  return request({
    url: "/office-supplies/queryOfficeSuppliesInfo",
    method: "get",
    params: params,
  });
}


//获取办公用品列表
export function queryOfficeSuppliesList(params) {
  return request({
    url: "/office-supplies/queryOfficeSuppliesList",
    method: "get",
    params: params,
  });
}


//保存办公用品领取记录
export function saveOSRecord(data) {
  return request({
    url: "/office-supplies/saveOSRecord",
    method: "post",
    data: data,
  });
}


//新增办公用品
export function saveOfficeSupplies(data) {
  return request({
    url: "/office-supplies/saveOfficeSupplies",
    method: "post",
    data: data,
  });
}


//新查询我的办公用品申领记录
export function getMyOSRecord(params) {
  return request({
    url: "/office-supplies/getMyOSRecord",
    method: "get",
    params: params,
  });
}




//获取办公用品申请详情
export function applyInfo(params) {
  return request({
    url: `/office-supplies/applyInfo/${params.id}`,
    method: "get",
  });
}